import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import img1 from '../images/about1.webp'
import img2 from '../images/about2.webp'
import img3 from '../images/about_bg.webp'
import { useMediaQuery } from 'react-responsive';

const About = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`about-section  position-relative section-padding`}>
        <Container className='position-relative'>
            <Row>
                <Col lg={7} md={12} sm={12} xs={12}>
                    <h2 className='mb-3 text-capitalize'>Spacious 3 BHK Apartments Tailored for Modern Living</h2>
                    <h6 className={`text-capitalize ${isMobile ? 'mb-3' : 'mb-5'}`}>Experience comfort, style, and functionality with our thoughtfully designed 3 BHK units.</h6>
                    <ul>
                        <li><span><IoCheckmarkCircleSharp /></span><p>Sizes: 1655 sq. ft. – 2455 sq. ft.</p></li>
                        <li><span><IoCheckmarkCircleSharp /></span><p>3 BHK Configuration: Perfect for families Or those seeking extra space</p></li>
                        <li><span><IoCheckmarkCircleSharp /></span><p>Verified tile flooring in living rooms and bedrooms</p></li>
                        <li><span><IoCheckmarkCircleSharp /></span><p>Granite countertops and modern fixtures  In the kitchen</p></li>
                        <li><span><IoCheckmarkCircleSharp /></span><p>Large balconies offering serene views</p></li>
                    </ul>
                </Col>
                <Col lg={5} md={12} sm={12} xs={12}>
                        {isMobile ? 
                        <Row className='mt-4'>
                            <Col md={6}><img src={img1} alt="dinning" className={`img-fluid ${isMobile ? 'mb-3' : ''}`}></img></Col>
                            <Col md={6}><img src={img2} alt="bedroom" className='img-fluid'></img></Col>
                        </Row>
                    :
                    <>
                    <img src={img1} alt="dinning" className='img-fluid mb-20'></img>
                    <img src={img2} alt="bedroom" className='img-fluid'></img>
                    </>
                    }
                    
                   
                </Col>
            </Row>
        </Container>
        <img src={img3} alt="background" className='img-fluid background-img position-absolute bottom-0 start-0'></img>
    </div>
  )
}

export default About
