import React from 'react'
import img1 from '../images/icon1.webp'
import img2 from '../images/icon2.webp'
import img3 from '../images/icon3.webp'
import img4 from '../images/icon4.webp'
import img5 from '../images/icon5.webp'
import img6 from '../images/icon6.webp'
import img7 from '../images/icon7.webp'
import img8 from '../images/icon8.webp'
import amy1 from '../images/amenity1.webp'
import amy2 from '../images/amenity2.webp'
import amy3 from '../images/amenity3.webp'
import amy4 from '../images/amenity4.webp'
import amy5 from '../images/aminity5.webp'
import amy6 from '../images/aminity6.webp'
import circle from '../images/circle.webp'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Amentinies = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const data = [
    {
        "id": 1,
        "image": amy3
    },
    {
        "id": 2,
        "image": amy2
    },
    {
        "id": 3,
        "image": amy5
    },
    {
        "id": 4,
        "image": amy6
    },
    {
        "id": 5,
        "image": amy3
    },
    {
        "id": 6,
        "image": amy5
    }
];
  return (
    <>
    <div className={`amenities-section ${isMobile ? 'section-padding' : 'section-padding'}`} id="amenities" >
      <Container className='position-relative'>
        <div className={`${isMobile ? 'text-start' : 'text-center'}`}>
         

          <h2 className='primary-color'>Unmatched Amenities for a Premium Lifestyle</h2>
         
          <h6 className='mb-20 mt-2 text-capitalize'>Enjoy the best of modern living with state-of-the-art amenities designed for every family member.</h6>
        </div>
        {!isMobile && 
          <>
          <img src={amy1} className='img-fluid position-absolute img2' alt=""></img>
          <img src={amy4} className='img-fluid position-absolute img1' alt=""></img>
          </>
        }
        <Row className='amenities-list mb-2 position-relative' style={{ paddingTop: '3%' }}>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingRight: !isMobile ? 'unset' : '5px' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img1} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Exclusive clubhouse</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ padding: !isMobile ? '0px 7px' : '', paddingLeft: isMobile ? '5px' : '' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img2} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Swimming pool</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingLeft: !isMobile ? 'unset' : '', paddingRight: isMobile ? '5px' : '' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img3} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Gym & sports</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingRight: !isMobile ? '' : '', paddingLeft: isMobile ? '5px' : 'unset' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img4} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Yoga/Aerobics Room</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingLeft: !isMobile ? '' : '', paddingRight: isMobile ? '5px' : 'unset' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img8} className='img-fluid position-relative mt-2' alt=""></img>
              </div>
              <p>Amphitheater</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingRight: !isMobile ? 'unset' : '', paddingLeft: isMobile ? '5px' : '' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img7} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Jogging</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ padding: !isMobile ? '0px 7px' : '', paddingRight: isMobile ? '5px' : '' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img6} className='img-fluid position-relative' alt=""></img>
              </div>
              <p>Cycling Track</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6} style={{ paddingLeft: !isMobile ? 'unset' : '5px' }} className={` ${isMobile ? 'mb-3' : 'mb-2'}`}>
            <div className='text-center amenities-card'>
              <div className='position-relative mt-2 image-div'>
                <img src={circle} className='img-fluid position-absolute' alt="" />
                <img src={img5} className='img-fluid position-relative' alt="" style={{width : isMobile ? '' : '18%'}}></img>
              </div>
              <p>Landscaped gardens</p>
            </div>
          </Col>
        </Row>
        {
          !isMobile &&
          <Row className='amenities-list'>
            <Col lg={6}>
              <img src={amy2} className='img-fluid' alt={amy2}></img>
            </Col>
            <Col lg={6}>
              <img src={amy3} className='img-fluid' alt={amy3}></img>
            </Col>
          </Row>
        }
        

      </Container>
    </div>
    {isMobile &&
      <>
      <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            // autoPlay={true}
            centerMode={false}
            // customTransition="all .5"
            // transitionDuration={500}
            className="mb-4"
            // containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 90
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}
            rewind={true}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {data.map((ele, i) => {
                return (
                    <img key={i} src={ele.image} alt="" className='img-fluid w-100' style={{cursor : 'pointer', maxWidth: '95%'}} /> 
                    )
            })
            }
        </Carousel>
      </>
    }
    </>
  )
}

export default Amentinies
