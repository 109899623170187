import React from 'react'
import bg from '../images/banner.webp'
import logo from '../images/logo_white.webp'
import { useMediaQuery } from 'react-responsive';
import { HiMiniBars3 } from "react-icons/hi2";
import { setMenuTrue, setToggleTrue } from '../redux/slice/changeState'
import { useSelector, useDispatch } from 'react-redux';
import MobileMenu from './MobileMenu';
import Contact from './Contact';
import { Link } from 'react-scroll';

const Banner = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const dispatch = useDispatch()
  const toggleMenu = useSelector((state) => state.toggle)

  return (
    <>
      {isMobile ?
        <>
          <div className='text-center banner' style={{ backgroundImage: `url(${bg})` }}>
            <nav className="mobile-nav">
              <div className="menu-toggle text-end" >
                <HiMiniBars3 size={30} className='text-white' onClick={()=>dispatch(setMenuTrue())} />
              </div>
            </nav>
            <div className='banner-title'>
              <img src={logo} className='img-fluid' alt="logo"></img>
            </div>
            <div className='banner-text text-white'>
              <h1 className='text-uppercase'>Your Dream 3 BHK Apartment Awaits at IRA Miracle, Kollur</h1>
              <h6 className='mt-4 px-4'>Luxury living in Kollur, Hyderabad with premium amenities, spacious designs and unbeatable pricing starting at ₹5,999/sq. ft.</h6>
              <div className='mt-5'>
                  <button className="common-btn text-white mb-3" onClick={() => dispatch(setToggleTrue())}>Book a Visit</button><br/>
                  <button className="common-btn text-white">Chat on Whatsapp</button>
                </div>
            </div>

          </div>
        </>
        :
        <>
          <div className='banner position-relative' >
            <img src={bg} className='img-fluid w-100' alt="miracle"></img>

            <div className='container'>
              <div className='position-absolute banner-title'>
                <img src={logo} className='img-fluid w-100' alt="logo"></img>
              </div>
              <div className='position-absolute banner-menu'>
                <ul className='primary-color text-end'>
                  <li><Link to="/" smooth={true} duration={1000}>Overview</Link></li>
                  <li><Link to="amenities" smooth={true} duration={1000}>Gallery</Link></li>
                  <li><Link to="amenities" smooth={true} duration={1000}>Floor Plans</Link></li>
                  <li><Link to="amenities" smooth={true} duration={1000}>Pricing & Availability</Link></li>
                  <li><a onClick={() => dispatch(setToggleTrue())}>Contact</a></li>
                </ul>
              </div>
              <div className='position-absolute banner-text text-white mb-5'>
                <h1 className='text-uppercase'>Your Dream 3 BHK Apartment
                  Awaits at IRA Miracle, Kollur</h1>
                <h6 className='pt-4'>Luxury living in Kollur, Hyderabad with premium amenities, spacious designs and unbeatable pricing starting at ₹5,999/sq. ft.</h6>
                <div className='mt-50'>
                  <button className="common-btn text-white " onClick={() => dispatch(setToggleTrue())}>Book a Visit</button>
                  <button className="common-btn text-white">Chat on Whatsapp</button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {toggleMenu.openMenu && <MobileMenu />}
      {toggleMenu.hastoggle && <Contact/>}
    </>
  )
}

export default Banner
