import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setMenuFalse, setToggleTrue } from '../redux/slice/changeState'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-scroll';

const MobileMenu = () => {
    const dispatch = useDispatch()
    const toggleMenu = useSelector((state) => state.toggle)
    const [navSolid, setNavSolid] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 75) {
            setNavSolid(true);
        } else {
            setNavSolid(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    
    return (
        <>
            <Modal show={toggleMenu.openMenu} onHide={() => dispatch(setMenuFalse())} className={`full-screen-modal ${toggleMenu.openMenu ? 'slide-in' : ''}`} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li><Link to="amenities" smooth={true} duration={1000} onClick={() => dispatch(setMenuFalse())}>Amenities</Link></li>
                        <li ><Link to="facing" smooth={true} duration={1000} onClick={() => dispatch(setMenuFalse())}>Floor Plan</Link></li>
                        <li ><Link to="pricing-section" smooth={true} duration={1000} onClick={() => dispatch(setMenuFalse())}>Pricing & Availability</Link></li>
                        <li ><Link to="location" smooth={true} duration={1000} onClick={() => dispatch(setMenuFalse())}>Location</Link></li>
                        <li ><a onClick={() => dispatch(setToggleTrue())}>Contact</a></li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MobileMenu
