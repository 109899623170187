import React from 'react'
import img2 from '../images/map.webp'
import img3 from '../images/location_bg.webp'
import img4 from '../images/location.webp'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { FaMapMarkerAlt } from "react-icons/fa";

const Location = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`location-section position-relative section-padding `}>
      <img src={img3} alt="location background" className='img-fluid position-absolute background_img top-0 start-0'></img>
      <Container>
        <h2 className='mb-2'>Prime Location with Seamless Connectivity</h2>
        <h6 className='mb-5'>Strategically located in Kollur, IRA Miracle ensures you stay connected to the city while enjoying the tranquility of a residential community.</h6>
        <Row className='align-items-center'>
          <Col lg={6} className='mb-3' style={{paddingRight: isMobile ? '' : '4%'}}>
            {/* <img src={img4} alt="location" className='img-fluid'></img> */}
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">05</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from ORR Exit no. 2 , Nagulapalli, Edulnagulapally, Telangana</p>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">08</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from many popular schools & educational institutions around Kollur</p>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">10</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from Neopolis also known as the Kokapet Special Economic Zone</p>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">15</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from Kokapet SEZ, another major commercial hub near Kollur</p>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">20</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from Financial District, home to top multinational corporations</p>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mt-3 duration-row'>
              <div className='col-lg-3 col-md-3 col-3 duration '>
                <div className='d-flex align-items-center duration-div'>
                  <FaMapMarkerAlt size={isMobile ? 20 : 40} className='' />
                  <div className="time-display">
                    <p className="text-white">25</p>
                    <p className="text-white">Mins</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-9 col-9 col-md-9 col-9 location'>
                <div>
                  <p>from top employment hotspots in and around Hyderabad</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className='map-image'>
            <img src={img2} alt="location" className='img-fluid'></img>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Location
