import React from 'react'
import img1 from '../images/p1.webp'
import img2 from '../images/p2.webp'
import img3 from '../images/p3.webp'
import img4 from '../images/p4.webp'
import img5 from '../images/p5.webp'
import img6 from '../images/p6.webp'
import img7 from '../images/p7.webp'
import img8 from '../images/p8.webp'
import img9 from '../images/p9.webp'
import img10 from '../images/p10.webp'
import prime from '../images/prime.webp'
import { FaMapMarkerAlt } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';

const Prime = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='modern-section section-padding'>
              
                    <Row className='pt-30'>
                        <Col lg={7} md={12} xs={12}>
                            <div className={`mb-4 ${isMobile ? 'text-start ' : ''}`}>
                                <h1 className='primary-color'>Why Choose:</h1>
                                <h1 className='primary-color'>IRA Miracle in Kollur?</h1>
                            </div>
                            <Row className={`${isMobile ? 'mb-3' : 'mb-4'}`} style={{padding : isMobile ? '0px 0px 0px 16px' : ''}}>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className='w-100 facility-row'>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img1} alt="" className='img-fluid' ></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div >
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Project Name:</p>
                                                        <p className='sec-span'>IRA Miracle</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Project Name:</span><br/>
                                                    <span className='sec-span'>IRA Miracle</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className={`w-100 facility-row`}>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img10} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div >
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Area</p>
                                                        <p className='sec-span'>3 Acres</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Area</span><br/>
                                                    <span className='sec-span'>3 Acres</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={`${isMobile ? 'mb-3' : 'mb-4'}`} style={{padding : isMobile ? '0px 0px 0px 16px' : ''}}>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className='w-100 facility-row'>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img5} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Status:</p>
                                                        <p className='sec-span'>Handover in Dec 2024</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Status:</span><br/>
                                                    <span className='sec-span'>Handover in Dec 2024</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className={`w-100 facility-row`}>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img3} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Total Flats</p>
                                                        <p className='sec-span'>274</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Total Flats</span><br/>
                                                    <span className='sec-span'>274</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={`${isMobile ? 'mb-3' : 'mb-4'}`} style={{padding : isMobile ? '0px 0px 0px 16px' : ''}}>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className='w-100 facility-row'>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img4} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Flat Area</p>
                                                        <p className='sec-span'>1655 To 2455 Sq. Ft.
</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Flat Area</span><br/>
                                                    <span className='sec-span'>1655 To 2455 Sq. Ft.
</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className={`w-100 facility-row`}>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img6} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Structure</p>
                                                        <p className='sec-span'>2B+G+14F</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Structure</span><br/>
                                                    <span className='sec-span'>2B+G+14F</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={`${isMobile ? 'mb-3' : 'mb-4'}`} style={{padding : isMobile ? '0px 0px 0px 16px' : ''}}>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className='w-100 facility-row'>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img2} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Unit Type</p>
                                                        <p className='sec-span'>3 BHK</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Unit Type</span><br/>
                                                    <span className='sec-span'>3 BHK</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className={`w-100 facility-row`}>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img7} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Club House</p>
                                                        <p className='sec-span'>20500 SFT</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Club House</span><br/>
                                                    <span className='sec-span'>20500 SFT</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={`${isMobile ? 'mb-3' : 'mb-4'}`} style={{padding : isMobile ? '0px 0px 0px 16px' : ''}}>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className='w-100 facility-row'>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img8} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>Pricing</p>
                                                        <p className='sec-span'>₹5,999/sq. ft.</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>Pricing</span><br/>
                                                    <span className='sec-span'>₹5,999/sq. ft.</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <Row className={`w-100 facility-row`}>
                                        <Col lg={2} md={2} xs={3} className='facility-img'>
                                            <img src={img9} alt="" className='img-fluid'></img>
                                        </Col>
                                        <Col lg={10} md={10} xs={9} className={`${isMobile ? 'd-flex align-items-center' : ''}`}>
                                            <div>
                                                {isMobile ?
                                                    <>
                                                        <p className='first-span'>RERA NO</p>
                                                        <p className='sec-span'>P01100002811</p>
                                                    </>
                                                    :
                                                    <>
                                                    <span className='first-span'>RERA NO</span><br/>
                                                    <span className='sec-span'>P01100002811</span>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <button class="common-btn text-white primary-background mt-3">Download Brochure Now</button>
                        </Col>
                        <Col lg={5} md={12} xs={12} className={`${isMobile ? 'mt-3' : 'd-flex justify-content-end'} position-relative`}>
                            {
                                isMobile ?
                                <>
                                    <div className='d-flex align-items-center prime-kollur position-absolute text-white primary-background'>
                                        <FaMapMarkerAlt size={isMobile ? 15 : 30} />
                                        <h6>Prime Kollur</h6>
                                    </div>
                                    <div className='text-center'>
                                        <img src={prime} className='img-fluid section2-img' alt="section2-img"></img>
                                    </div>
                                </>
                                : 
                                <>
                                <div className='w-100 text-center'>
                                <div className='d-flex position-absolute prime-kollur align-items-center text-white primary-background'>
                                    <FaMapMarkerAlt size={isMobile ? 15 : 30} />
                                    <h6>Prime Kollur</h6>
                                </div>
                            </div>
                             <img src={prime} className='img-fluid w-100 ' alt="section2-img"></img>
                            {/*<div className='position-absolute second-img'><img src={sec2} className='img-fluid w-100' alt="section2-img"></img></div> */}
                                </>
                            }
                            
                        </Col>
                    </Row>
                
            </div>
    )
}

export default Prime
