import axios from 'axios';

const Apis = () => {
    axios.defaults.baseURL = "https://irarealty.in/cms/";

    const saveContactFormdata = async (input_data) => {
        const response = await axios.post("api/submitContact", input_data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response;
    }

    
    return {
        saveContactFormdata: saveContactFormdata,
    }
}

export default Apis
