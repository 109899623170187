import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MdPhoneInTalk } from "react-icons/md";
import { PiWhatsappLogoLight } from "react-icons/pi";
import { GrFacebookOption } from "react-icons/gr";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../images/logo.webp'
import logo1 from '../images/ira_logo.webp'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch } from 'react-redux'
import { Link } from 'react-scroll';

const Footer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const isLargeMobile = useMediaQuery({query:'(max-width:425px)'});
    const isSmallMobile = useMediaQuery({ query: '(max-width: 375px)' });
    const dispatch = useDispatch();
    return (
        <div className={`footer ${isMobile ? 'mt-4' : 'mt-5'}`}>
            <Container className='mb-20'>
                <Row className={`${isMobile ? 'px-20' : ''}`}>
                    <Col lg={6} md={6} sm={5} xs={5} className={`footer-logo-column ${isMobile ?'pt-3 ':'pt-30 pb-30' }`}>
                        <div className={`footer-logo  ${isMobile ? 'mr-20 pb-3' : 'd-flex justify-content-between mr-50'}`}>
                            <img src={logo} alt="logo" className={`${isMobile ? 'pb-2': ''} `}></img>
                            <img src={logo1} alt="ira_logo" className='img-fluid'></img>
                        </div>
                        <div className={`${isMobile ? 'mr-20': 'mr-50'}`}>
                            <ul>
                                <li>Home</li>
                                <li><Link to="facing" smooth={true} duration={1000}>Floor Plans</Link></li>
                                <li onClick={() => dispatch(setToggleTrue())}>Contact Us</li>
                                <li><a href="https://irarealty.in/privacy-policy" target="_blank" rel="noreferrer" className='text-decoration-none text-dark'>Privacy Policy</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={7} xs={7} className={`${isMobile ?'pt-3 d-flex flex-column':'pt-30 pb-30' }`}>
                        <Row className={`mb-7 ${isMobile ? '' : 'ml-50'}`}>
                            <div className='col-12'>
                                <div>
                                    <p className='footer-header primary-color'>Address:</p>
                                    <p className='address-text'>
                                        4-49/2, Besides Anvaya Conventions Road, Financial District,</p>
                                    <p className='address-text'>Vattinagulapally,</p>
                                    <p className='address-text'>Hyderabad - 500 032, Telangana
                                    </p>
                                </div>
                            </div>
                        </Row>
                        <Row className={`mb-7 ${isMobile ? '' : 'ml-50'}`}>
                            <div className='col-lg-6 col-md-12'>
                                <p><span className='footer-header primary-color'>Phone:</span><NavLink to="tel:9121777777" target="_blank" className="text-dark text-decoration-none"> +91 7075 506 189</NavLink></p>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <p><span className='footer-header primary-color'>Email:</span><a className="text-decoration-none text-dark" href="mailto:info@irarealty.in" rel="noreferrer" target="_blank">info@irarealty.in</a></p>
                            </div>
                        </Row>
                        <Row className={` ${isMobile ? 'flex-fill' : 'ml-50 mb-7'}`}>
                            <div className='d-flex align-items-center'>
                                <p><span className="footer-header primary-color" >Let's Connect:</span></p>
                                <NavLink to="tel:7075506189" target="_blank"><MdPhoneInTalk size={isMobile ? 20 : 35} className='padding-10 text-dark' /></NavLink>
                                <NavLink to="https://wa.api-whatsapp.in/Nsyl70" target="_blank"><PiWhatsappLogoLight size={isMobile ? 20 : 35} className='padding-10 text-dark' /></NavLink>
                                <NavLink to="https://www.facebook.com/IRARealtyIndia" target="_blank"><GrFacebookOption size={isMobile ? 20 : 35} className='padding-10 text-dark' /></NavLink>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {/* <div className='text-center copyright'>
                <Container className='mb-20'>
                    {isLargeMobile ?
                        isSmallMobile ?
<p>Disclaimer: "This is purely a conceptual presentation and not a legal offering. The promoters reserve the right to make changes in elevation, specifications, and plans as deemed fit</p>
                        :
                        <>
                        <p>Disclaimer: "This is purely a conceptual presentation and not a legal.</p>
                        <p>offering. The promoters reserve the right to make changes in elevation,</p>
                        <p>specifications, and plans as deemed fit</p>
                    </>

                        :
                        <p>Disclaimer: "This is purely a conceptual presentation and not a legal offering. The promoters reserve the right to make changes in elevation, specifications, and plans as deemed fit</p>
                    }
                </Container>
            </div> */}
        </div>
    )
}

export default Footer
