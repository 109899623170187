import React from 'react'
import img1 from '../images/common.webp'
import Ready from './Ready'
import Testimonial from './Testimonial'
import { useMediaQuery } from 'react-responsive';

const CommonBackground = ({child}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`common-background position-relative section-padding `}>
      <img src={img1} alt="background" className='img-fluid w-100 start-0'></img>
      {
        child === 'ready' ? <Ready /> : <Testimonial />
      }
      
    </div>
  )
}

export default CommonBackground
